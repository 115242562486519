body:hover .mouse-trailer-secondary {
  opacity: 1;
}

.mouse-trailer {
  position: fixed;

  backdrop-filter: blur(10px);
  border-radius: 50%;
  z-index: 100000;
  box-shadow: 0 0 1vmin #00000045;
  pointer-events: none;
  border: none;

  &-primary {
    height: 1rem;
    width: 1rem;
    background-color: #ffffff91;
  }
  &-secondary {
    position: fixed;
    height: 3rem;
    width: 3rem;
    background-color: #ffffff23;
    transition: opacity 2s ease;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    &-second {
      height: 2vmin;
      width: 2vmin;
      border-radius: 50%;
      background-color: black;
    }
    svg {
      fill: white;
      border-radius: 50%;
      path {
        box-shadow: 0 0 1vmin #00000045;
      }
    }
  }
}
