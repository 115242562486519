* {
  margin: 0;
  cursor: none;
}
#root {
  font-family: "Montserrat", sans-serif;
  --background-color: black;
  --primaryColor: white;
  --secondaryColor: black;
  --borderRadius: 5px;
  --transitionTime: 400ms;
  --mobileWidth: 600px;
  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes scale-zero-to-one {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes to-bottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes bottom-to-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes top-to-bottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes left-to-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes right-to-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes invisible-to-visible {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes visible-to-invisible {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes move-in-all-direction {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-50%);
  }
  40% {
    transform: translateX(50%);
  }
  60% {
    transform: translateY(-50%);
  }
  80% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}
